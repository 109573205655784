export const postMessagePixallData = (eventType, eventSource, svocId, eventFields) => {  
  var contentEvent = {
    pxamMessageType: "pxamContentEvent",
    eventType: eventType,
    eventSource: eventSource,
  };
  if (svocId) {
    contentEvent.eventFields = {
      listingCode: svocId.toString(),
      ...eventFields,
    };
  }
  var parentWindow = window.opener || window.top;
  parentWindow.postMessage(JSON.stringify(contentEvent), "*");
}

export const trackGoogleAnalyticsImpression = (svocId, pageName) => {  
  var eventResult = `rating`;
  var data = getGaDataLayerObject(svocId, eventResult, true);
  var postMessageObject = getGaPostMessageObject(data, true);
  if(window) {
    window.postMessage(JSON.stringify(postMessageObject), '*');
    if(pageName !== 'ddp' && window.dataLayer?.push) {
      window.dataLayer.push(data);
    }
  }
  
}

/* Postmessage object template
{
  type: 'csAnalyticsEvent',
  event: {
    type: 'click|impression',
    googleAnalytics: {
      props: dataLayerPushObject
    }
}
*/
const getGaPostMessageObject = (gaDataLayerObject, isImpression = true) => {
  var obj = {
    type: 'csAnalyticsEvent',
    event: {
      type: isImpression ? 'impression' : 'click',
      googleAnalytics: {
        props: gaDataLayerObject
      }
    }
  };
  return obj;
}

const getGaDataLayerObject = (svocId, eventResult, isImpression = true,) => {
  var data = {
    event: 'analytics_event',
    eventElement: 'dealer-rating',
    eventAction: isImpression ? 'impression' : 'click',
    eventResult: 'rating',
    eventNonInteractive: isImpression ? true : false,
    eventData: {
      svocId: `${svocId}`
    }
  }

  return data;
}