import React, { useState, useRef, useContext, useEffect } from 'react';
import styled from '@emotion/styled';
import { Link } from '@argo/ui-elements';
import RatingsAndReviewsContext from '../../contexts';
import { popoverTextCopy } from '../../constants/textCopy';
import Popover from './Popover';

const LinkContainer = styled.div`
  ${({ widgetTheme }) => 
    widgetTheme ? 
    widgetTheme.typography.types.body : ''
  };
  padding-bottom: ${({ paddingBottom }) =>
    `${(paddingBottom) || '20px'}`};
`

const PopoverLink = ({scrollIntoView = true, showArrow = true, centerVertical = false, paddingBottom = '20px', textOverride = ''}) => {
  const linkContainerElement = useRef(null);
  const { popoverIsShown, setPopoverIsShown, widgetTheme } = useContext(RatingsAndReviewsContext);
  const [coordinates, setCoordinates] = useState({ x: 0, y: 0 });

  const popoverTextContent = popoverTextCopy;
  const text = textOverride || 'How is the KBB.com rating calculated?';

  // scroll the link up into view when clicked
  const handleLinkClick = event => {
    if (popoverIsShown && scrollIntoView) {
      linkContainerElement.current.scrollIntoView();
    }

    // dynamically get the new positioning of the linkContainer after scroll
    const domRect = linkContainerElement.current.getBoundingClientRect();
    setCoordinates({ x: domRect.x, y: domRect.y});
  }
  
  
  // handle popover being cutoff when embedded
  if(popoverIsShown && document.getElementById("embedded-layout") && document.getElementById("popover-container")){
    var iframeHeight = document.getElementById("embedded-layout").scrollHeight;
    var popoverHeight = document.getElementById("popover-container").clientHeight;
    var offset = parseInt(getComputedStyle(document.getElementById("popover-container")).top);

    if((popoverHeight + offset) > iframeHeight){
      iframeHeight = popoverHeight + offset;
    }

    window.parent.postMessage({
      eventSource: 'EmbeddedWidget',
      eventAction: 'heightChanged',
      height: iframeHeight,
    }, '*');
    
  }

  useEffect(() => {
    handleLinkClick();
  }, [popoverIsShown]);

  return (
    <div>
      <LinkContainer data-cy="linkContainer" 
        widgetTheme={widgetTheme}
        id={'#linkContainer'}
        ref={linkContainerElement}
        paddingBottom={paddingBottom}
      >
        <Link data-cy="popoverLink"
          type={'regular'}
          onClick={() => {
            setPopoverIsShown(!popoverIsShown);
          }}>
          <span data-cy="popover-text">
            {text}
          </span>
        </Link>
      </LinkContainer>
      <Popover
        coordinates={coordinates}
        showArrow={showArrow}
        centerVertical={centerVertical}
        isShown={popoverIsShown}
        onClose={() => { setPopoverIsShown(false); }}
        textContent={popoverTextContent}
      />
    </div>
  )
}

export default PopoverLink;
