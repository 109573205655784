import { Circle, Rectangle } from "@argo/ui-elements";
import React from 'react';

const LeaderboardSkeletonLoader = () => {
  return (
    <div className='leaderboard-skeleton-container'>
      <div className="leaderboard-text-skeleton">
        <Rectangle padding='xs' />
        <Rectangle padding='xs' />
      </div>
      <div className="read-reviews-btn-skeleton">
        <Rectangle padding='xs' />
        <Rectangle padding='xs' />
      </div>
      <div className='rating-skeleton'>
        <div className='left-section'>
          <Circle padding='md' />
        </div>
        <div className="right-section">
          <Rectangle padding='xs' width='100%' />
          <Rectangle padding='xs' width='100%' />
        </div>
      </div>
    </div>
  );
}

export default LeaderboardSkeletonLoader;
