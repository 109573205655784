import { useState, useCallback, useMemo } from 'react';
import { getZipCodeByLatAndLong } from './../helpers/apiHelper';

export const useZipcode = () => {
    const initialZipInfo = { zipCode: '20146', state: { code:'VA', name: 'Virginia' } };
    const [zipInfo, setZipInfo] = useState( { ...initialZipInfo } );

    const validateGeolocation = useCallback(async ({ latitude, longitude }) => {
        const data = getZipCodeByLatAndLong(latitude, longitude);
        return data;
      }, []);

    const requestZip = useCallback(() => {
        navigator.geolocation.getCurrentPosition(async result => {
            try {
                const locationInfoByLatLng = await validateGeolocation(result.coords);
                if (locationInfoByLatLng.zipCode) {
                  setZipInfo({ zipCode: locationInfoByLatLng.zipCode, state: locationInfoByLatLng.state, city: locationInfoByLatLng.city });
                }
            } catch (e) {
              // No need to log anything
            }
        });
    }, [validateGeolocation, setZipInfo]);

    const location = useMemo(() => ({
        zipInfo,
        zipCode: zipInfo.zipCode,
        state: zipInfo.state,
        city: zipInfo.city,
        requestZip,
      }), [
        zipInfo,
        requestZip,
      ]);

    return location;
}