import React, { useContext, useState } from 'react';
import styled from '@emotion/styled';
import RatingsAndReviewsContext from '../../contexts';
import Review from '../../components/ReviewSummary/Review';
import Alert from '@argo/ui-elements/Alert';


const Text = styled.p`
  ${({ widgetTheme }) => 
    widgetTheme ? 
    widgetTheme.typography.defaults.SSemi : ''
  };
  margin: 0;
  padding-bottom: 8px;
  text-transform: none;
`;

const ReviewCountText = styled.span`
  ${({ widgetTheme }) => 
    widgetTheme ? 
    (`${widgetTheme.typography.defaults.body12}; ${widgetTheme.typography.fontColorDefaults.body}`) : ''
  };
  font-size: 13px !important;
  padding-left: 0.15cm;
`;

const Reviews = ({onAccordionButtonClick}) => {
  const {
    queryParameters,
    kelleyBlueBookReviews,
    widgetTheme,
    errors,
    svocId,
    reviewEligible,
  } = useContext(RatingsAndReviewsContext);


  const theme = queryParameters?.theme || 'light';
  let isInverse = theme=="dark" ? true : false;
  const kelleyBlueBookReviewsList = kelleyBlueBookReviews.dataList;

  let currentReviewCount = 0;
  let reviewsCount = 11;
  let hasErrors = errors && errors.length;

  const [maxReviewCount, setMaxReviewCount] = useState(reviewsCount);



  const reviewText = kelleyBlueBookReviewsList?.length == 1 ? "Review" : "Reviews";

  
  
  let intl = Intl.NumberFormat();
  let reviewCountText = intl.format(kelleyBlueBookReviewsList?.length ?? 0) + " " + reviewText;


  // if there are no reviews, show the fallback else display the reviews
  if(!kelleyBlueBookReviewsList || kelleyBlueBookReviewsList.length === 0) {
    if(reviewEligible == true){
      return (
      <div>
        <Text widgetTheme={widgetTheme}>
           Reviews
            <ReviewCountText data-cy="embeddedReviewsCount" widgetTheme={widgetTheme}>
              ({reviewCountText})
            </ReviewCountText>
        </Text>
        <Alert
          data-analytics="alert"
          data-lean-auto="alert"
          data-css="no-review"
          heading=" "
          text="There are no reviews for this dealer."
          type="information"
        />
      </div>
    );
    }
    return (
      <div>
        <Text widgetTheme={widgetTheme}>
           Reviews
       
            <ReviewCountText data-cy="embeddedReviewsCount" widgetTheme={widgetTheme}>
              ({reviewCountText})
            </ReviewCountText>
          
        </Text>
        <Alert
          data-analytics="alert"
          data-lean-auto="alert"
          data-css="no-review"
          heading=" "
          text={hasErrors
            ? "An error has occurred and this data is unavailable.  Please check again later"
            : "There are no reviews for this dealer."
          }
          type={hasErrors ? "error" : "information"}
        />
      </div>
    );
  }
  else {
    return (
      <div id='reviewContainer' style={{paddingBottom:'3px'}}>
        <Text widgetTheme={widgetTheme}>
        Reviews
            <ReviewCountText data-cy="embeddedReviewsCount" widgetTheme={widgetTheme}>
              ({reviewCountText})
            </ReviewCountText>
        </Text>
        {kelleyBlueBookReviewsList.slice(0, maxReviewCount).map((value, index) => {
          currentReviewCount += 1;
          return (
            <Review
              reviewerName={value.reviewerName}
              rating={value.rating}
              reviewDate={value.reviewDate}
              reviewText={value.reviewText}
              serviceRating={value.serviceRating}
              salesRating={value.salesRating}
              responses={value.responses}
              key={index}
              onAccordionButtonClick={onAccordionButtonClick}
              isInverse={isInverse}
            />
          );
        })}
      </div>
    );
  }
};

export default Reviews;
