import React, { useState, useEffect } from 'react';
import SquareRatings from './layouts/SquareRatings';
import DynamicWidget from './layouts/DynamicWidget';
import RatingsAndReviewsContext from './contexts';
import { getDealerRatingsData, authenticateClientId } from './helpers/apiHelper';
import {  kbbTheme } from '@argo/themes';
import { ThemeProvider } from 'emotion-theming';
import { kbb } from './constants/index';
import LeaderboardRatings from './layouts/LeaderboardRatings';
import HorizontalRatings from './layouts/HorizontalRatings';
import SmallRatings from './layouts/SmallRatings';
import ReviewSummary from './layouts/ReviewSummary';
import SmallBadgeSkeleton from './layouts/SmallBadgeSkeleton';
import SquareBadgeSkeleton from './layouts/SquareBadgeSkeleton';
import HorizontalBadgeSkeleton from './layouts/HorizontalBadgeSkeleton';
import LeaderboardSkeleton from './layouts/LeaderboardSkeleton';
import ReviewSummarySkeleton from './layouts/ReviewSummarySkeleton';
import DefaultSkeletonLoader from './layouts/SkeletonLoader';
import { useZipcode } from './hooks/useZipcode';

const getPathName = () => {
  try {
    let pathName = window.location.pathname

    // prune '/Prod' from the url if we see it (this is from the api gateway direct url)
    if(pathName && pathName.includes('/prod')) {
      pathName = pathName.substring(5);
    }
    return pathName.toLowerCase();
  } catch (e) {
    return '';
  }
}

// pathName example '/widget/ratings/clientid/99998'.
// ratings is the text between `/widget/` and `/clientid/`
const getWidgetFromPathName = (pathName) => {
  try {
    let widgetPart;
    if(pathName.includes('/widget/')){
      widgetPart = '/widget/';
    }
    else if(pathName.includes('/syndicated-widget/')){
      widgetPart = '/syndicated-widget/';
    }

    // remove /widget/ or /syndicated-widget/ from path
    var pathPart = pathName.substring(widgetPart.length);

    // get the widget type from the string before the next '/'
    let widgetName = pathPart;
    if (pathPart.includes('/')){
      widgetName = pathPart.substring(0, pathPart.indexOf('/'));
    } 

    return widgetName;

  } catch (e) {
    return '';
  }
}

const getStaticBadge = (pathName, dealerId, kbbRatings, widgetTheme, isAuthenticated, isCoreApiHealthy, optionalDedicatedPageUrl, svocId, userZip) => {
  const subWidget = getSubWidgetTypeForStaticBadge(pathName);
  switch(subWidget){
    case 'leaderboard':
        return (
          <RatingsAndReviewsContext.Provider
            value={{
              svocId,
              kbbRatings,
              widgetTheme,
              isAuthenticated,
              isCoreApiHealthy,
              optionalDedicatedPageUrl,
              clientId,
              userZip,
              }}
            >
              <ThemeProvider theme={widgetTheme}>
                <LeaderboardRatings />
              </ThemeProvider>
          </RatingsAndReviewsContext.Provider>
            );
    case 'square':
      return (
        <RatingsAndReviewsContext.Provider
          value={{
            svocId,
            kbbRatings,
            widgetTheme,
            isAuthenticated,
            isCoreApiHealthy,
            optionalDedicatedPageUrl,
            clientId,
            userZip,
          }}
        >
          <ThemeProvider theme={widgetTheme}>
            <SquareRatings />
          </ThemeProvider>
        </RatingsAndReviewsContext.Provider>
      );
    case 'horizontal':
      return (
        <RatingsAndReviewsContext.Provider
          value={{
            svocId,
            kbbRatings,
            widgetTheme,
            isAuthenticated,
            isCoreApiHealthy,
            optionalDedicatedPageUrl,
            clientId,
            userZip,
          }}
        >
          <ThemeProvider theme={widgetTheme}>
            <HorizontalRatings />
          </ThemeProvider>
        </RatingsAndReviewsContext.Provider>
      );
    case 'small':
      return (
        <RatingsAndReviewsContext.Provider
          value={{
            svocId,
            kbbRatings,
            widgetTheme,
            isAuthenticated,
            isCoreApiHealthy,
            optionalDedicatedPageUrl,
            clientId,
            userZip,
          }}
        >
          <ThemeProvider theme={widgetTheme}>
            <SmallRatings />
          </ThemeProvider>
        </RatingsAndReviewsContext.Provider>
      );
    default:
      return null;
  }
}

const getSubWidgetTypeForStaticBadge = (pathName) => {
  const pathNameLowerCase = pathName.toLowerCase();
  const staticBadgeType = '/syndicated-widget/staticbadge/';
  const clientIDSub = '/clientid';
  const beginningLocation = pathNameLowerCase.indexOf(staticBadgeType) + staticBadgeType.length;
  const endLocation = pathNameLowerCase.indexOf(clientIDSub);

  if(beginningLocation == -1 || endLocation == -1){
    return '';
  }

  const result = pathNameLowerCase.substring(beginningLocation, endLocation);

  return result;
}

// pathName example '/widget/ratings/clientid/99998'.
// widget is the text between `/widget/` and `/clientid/`.
const getClientIdFromPathName = (pathName) => {
  try {
    var clientIdPart = '/clientid/';

    // find the index of the /clientid/ string
    var clientIdIndex = pathName.indexOf(clientIdPart);

    // get the string after the /clientid/ text
    var pathPart = pathName.substring(clientIdIndex + clientIdPart.length);

    // pathPart my be equal to just 99998 or 99998?abc=key
    // need to pull everything before the ? if there are query string parameters or # if there is a hashbang
    if(pathPart.indexOf('?') === -1) {
      return pathPart;
    }
    else {
      return pathPart.substring(0, pathPart.indexOf('?'));
    }

  } catch (e) {
    return '';
  }
}

const getQueryParamsFromUrl = () => {
  if (typeof window === 'undefined' || !window.location.search) {
    return {};
  }
  try {
    const pairs = window.location.search.slice(1).split('&');
    const queryObject = pairs
      .filter((pair) => pair.indexOf('=') > 0)
      .reduce((acc, pair) => {
        let [key, value] = pair.split('=');
        key = key.toLowerCase();
        if (key !== 'apikey') {
          value = value.toLowerCase();
        }
        acc[key] = decodeURIComponent(value);
        return acc;
      }, {});
    return queryObject;
  } catch (e) {
    return {};
  }
};

const pathName = getPathName();
const queryParams = getQueryParamsFromUrl();
let requestedWidget = "demo";

if (pathName && pathName != "/" && (pathName.includes('widget') || pathName.includes('syndicated-widget'))){
  requestedWidget = getWidgetFromPathName(pathName);
} 
else if (pathName.length>=12 
  && pathName.substring(0,12) == ('/our-reviews') 
  && (pathName.length==12 || pathName[12] == "?")
)
{
  requestedWidget = "our-reviews";
} 

let clientId = "";
if (pathName && pathName.includes("clientid")){
  clientId = getClientIdFromPathName(pathName);
}

const App = () => {
  // Set var from query param
  const debug = queryParams.debug;
  let noCache = queryParams.nocache;
  let siteConfig = queryParams.siteconfig;
  let optionalDedicatedPageUrl = queryParams.linkto
  siteConfig = siteConfig && siteConfig.length > 0 ? siteConfig : kbb;
  const { zipCode, requestZip } = useZipcode();

  if (!noCache) {
    noCache = false;
  }
  let widgetTheme = kbbTheme;

  // Default data
  const [queryParameters, setQueryParameters] = useState(queryParams);
  const [initialRender, setInitialRender] = useState(true)
  const [errors, setErrors] = useState([])
  const [dealerId, setDealerId] = useState('')
  const [svocId, setSvocId] = useState('');
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isCoreApiHealthy, setIsCoreApiHealthy] = useState(false);
  const [kbbRatings, setKbbRatings] = useState({
    'rating': 0,
    'count': 0,
    'url': ''
  });
  const [kelleyBlueBookReviews, setKelleyBlueBookReviews] = useState({
    'dataList': [],
    'first': null,
    'hasMore': false,
    'next': null,
    'previous': null,
    totalCount: 0
  });
  const [reviewEligible, setreviewEligible] = useState(false);
  const [popoverIsShown, setPopoverIsShown] = useState(false);
  const [userZip, setUserZip] = useState(zipCode);

  useEffect(() => {  
    setUserZip(zipCode);
  },[zipCode])

  useEffect(async () => {    
    requestZip();
    let svocId = ''; // declare this here to make this value available to the call below
    // get svocId from clientId
    if (requestedWidget != "demo") {
      await authenticateClientId(clientId)
      .then((data) => {
        setIsCoreApiHealthy(true);
        if(data && !isNaN(data)) {
          svocId = data;
          setSvocId(svocId);
          setIsAuthenticated(true);
        }
        else {
          setIsAuthenticated(false);
          setErrors(data);
        }
      }).catch(err => {
        setErrors(err);
        console.error(err);
      });
    }

    if(svocId) {
      // get dealer ratings and reviews data 
      // obtaining ratings data
      var includeReviews = requestedWidget == 'review';
      await getDealerRatingsData(clientId, noCache, includeReviews)
        .then((data) => {
          if (data) {
            if (data.dealerReviews && Object.keys(data.dealerReviews).length > 0) {
              setKelleyBlueBookReviews(data.dealerReviews);
            }

            if (data.dealerRatings && Object.keys(data.dealerRatings).length > 0) {
              setDealerId(data.dealerRatings.dealerId);
              setreviewEligible(data.dealerRatings.isReviewEligible)
              if (data.dealerRatings.providerRatings) {
                const dealerRatings = data.dealerRatings.providerRatings.reduce(function (map, obj) {
                  map[obj.provider] = obj;
                  return map;
                }, {});

                setKbbRatings(dealerRatings.KBB);
              }              
            }
            if (data.errors) {
              setErrors(data.errors);
            }
          }
          setInitialRender(false);
        }).catch(err => {
          console.error(err);
          setInitialRender(false);
        });
    }
    else {
      setInitialRender(false);
    }
  }, []);

  if (initialRender === false) {

    //  console log debug information if requested in url.
    if (debug === 'true') {
      console.log('debug data:', {
        'requestedWidget': requestedWidget,
        'clientId': clientId,
        'svocId': svocId,
        'userZip': userZip,
        'isAuthenticated': isAuthenticated,
        'dealerId:': dealerId,
        'queryParameters': queryParams,
        'kbbRatings': kbbRatings,
        'kelleyBlueBookReviews': kelleyBlueBookReviews,
        'errors': errors,
      });
    }
    
    // render appropriate widget based on path.
      switch(requestedWidget) {   
        case 'dynamicbadge':
          return (
            <RatingsAndReviewsContext.Provider
              value={{
                svocId,
                kbbRatings,
                kelleyBlueBookReviews,
                widgetTheme,
                isAuthenticated,
                isCoreApiHealthy,
                optionalDedicatedPageUrl,
                setKelleyBlueBookReviews,
              }}
            >
              <ThemeProvider theme={widgetTheme}>
                <DynamicWidget />
              </ThemeProvider>
            </RatingsAndReviewsContext.Provider>
          );
        case 'staticbadge':
          return getStaticBadge(pathName, dealerId, kbbRatings, 
                                widgetTheme, isAuthenticated, 
                                isCoreApiHealthy, 
                                optionalDedicatedPageUrl,
                                svocId, userZip);
        case 'review':
          return (
            <RatingsAndReviewsContext.Provider
              value={{
                svocId,
                clientId,
                dealerId,
                kbbRatings,
                widgetTheme,
                isAuthenticated,
                isCoreApiHealthy,
                optionalDedicatedPageUrl,
                kelleyBlueBookReviews,
                setKelleyBlueBookReviews,
                popoverIsShown,
                setPopoverIsShown,
                reviewEligible,
                setreviewEligible,
                userZip,              
              }}
            >
              <ThemeProvider theme={widgetTheme}>
                <ReviewSummary /> 
              </ThemeProvider>
            </RatingsAndReviewsContext.Provider>
          );     
        default: 
          return (
            <div></div>
          )
      }
  }
  // Added logic for Skeleton loader. Original skeleton is moved under default.   
 switch(requestedWidget) {
    case 'staticbadge':
      const subWidget = getSubWidgetTypeForStaticBadge(pathName);
      switch(subWidget) {
        case 'leaderboard':
          return (
            <LeaderboardSkeleton />
          );
        case 'horizontal': //horizontal static badge skeleton
          return (
            <HorizontalBadgeSkeleton />
          );
        case 'square': //square static badge skeleton
          return ( 
            <SquareBadgeSkeleton/>
          );
        case 'small': //small static badge skeleton
          return (
            <SmallBadgeSkeleton />
          );
        default: 
          return (
            <SmallBadgeSkeleton />
          );
      }
    case 'review':
      return (
        <ReviewSummarySkeleton />
      );
    default: //Original skeleton
      return (
        <DefaultSkeletonLoader/>
      );
 }  
}

export default App;
