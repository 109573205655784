import React from 'react';

const RatingsAndReviewsContext = React.createContext({
  initialRender: true,
  pageName: '',
  dealerName: '',
  queryParameters: {},
  kbbRatings: {},
  svocId: {},
  userZip: {},
  setInitialRender: () => {},
  setDealerName: () => {},
  setQueryParameters: () => {},
  setKbbRatings: () => {},
  setSvocId: () => {},
  setUserZip: () => {},
});

export default RatingsAndReviewsContext;
