import React from 'react';
import Accordion from './CustomAccordion';
// moment is used to convert time stamp (e.g. 2020-10-27T23:07:49+00:00)
// into a relative time (e.g. 4 days ago)
import moment from 'moment';

// moment does not support weeks so this is a custom rule. (e.g. moment
// would should 7 days instead of 1 week)
moment.updateLocale('en', {
  relativeTime: {
    dd: function (number) {
      if (number < 7) {
        return number + ' days'; // Moment uses "d" when it's just 1 day.
      }
      else {
        var weeks = Math.round(number / 7);
        return weeks + ' ' + (weeks > 1 ? 'weeks' : 'week');
      }
    },
  }
});

const Review = ({ reviewerName, rating, reviewDate, reviewText, responses, onAccordionButtonClick, serviceRating, salesRating, isInverse=true }) => {
  let name = '';
  if (reviewerName) {
    name = reviewerName;
  }

  // convert datetime to text (e.g. 2020-10-27T23:07:49+00:00 into 4 days ago)
  const relativeReviewDate = moment(reviewDate).fromNow();

  // sort responses by oldest to newest date
  responses.sort(function (a, b) {
    if (a.responseDate < b.responseDate)
      return -1 
    if (a.responseDate > b.responseDate)
      return 1
    return 0
  });

  // map list to be readable by the custom accordion
  const responsesList = responses.map(function (obj) {
    return {
      accordionSubText: obj.responseText,
      displayaccordionSubTextLabel: obj.isFromDealer
    }
  });

  //if salesRating and serviceRating are both null, then the rating will default to a salesRating
  let hasSalesRating = salesRating || ( rating && serviceRating == null );

  return (
    <Accordion
      accordionLabel={name}
      starRating={rating}
      timeStamp={relativeReviewDate}
      accordionSubTextLabel={'Owner Response'}
      accordionSubTextList={responsesList}
      isInverse={isInverse}
      maxWidthElementId={'reviewContainer'}
      onAccordionButtonClick={onAccordionButtonClick}
      hasServiceRating={serviceRating != null}
      hasSalesRating={hasSalesRating}
    >
      {reviewText}
    </Accordion>
  );
};

export default Review;
