import React, { useContext, useState, useEffect } from 'react';
import styled from '@emotion/styled';
import RatingsAndReviewsContext from '../contexts';
import RatingSection from '../components/RatingSection';
import { getDealerReviewsData } from '../helpers/apiHelper';
import Box from '@argo/ui-elements/Box';
import Button from '@argo/ui-elements/Button';
import Carousel from '@argo/base-patterns/Carousel';
import Rating from '@argo/ui-elements/Rating';
import { kbbBaseUrl, dynamicReviewsCountLimit } from '../constants';
import moment from 'moment';

const ErrorText = styled.p`
  ${({ widgetTheme }) => 
    widgetTheme ? 
    widgetTheme.typography.defaults.SSemi : ''
  };
  text-transform: none;
  font-size: 12px;
`;

const DynamicWidgetButtonDiv = styled.div`
  margin-top: 40px;
  text-align: center;
  @media (max-width: 643px) {
    margin-top: 16px;
    margin-left 0px;
  }  
`;

const DynamicWidget = () => {
    const {
      svocId,
      kbbRatings,
      kelleyBlueBookReviews,
      widgetTheme,
      isAuthenticated,
      isCoreApiHealthy,
      optionalDedicatedPageUrl,
      setKelleyBlueBookReviews
    } = useContext(RatingsAndReviewsContext);

    let reviewsCountLimit = Number(dynamicReviewsCountLimit);

    const [maxReviewCount, setMaxReviewCount] = useState(reviewsCountLimit);
    const [currentReviewCount, setCurrentReviewCount] = useState(0);
    useEffect(() => {}, [maxReviewCount]);

    let ctaUrl = optionalDedicatedPageUrl ?? `${kbbBaseUrl}/syndicated/reviews/clientid/${svocId}&origin=dealersyndicatedwidget`;

    const handleCTAButton = () => {
        if (optionalDedicatedPageUrl){
          window.top.location.href = optionalDedicatedPageUrl;
        } 
        else {
          window.open(ctaUrl,'popup','width=800,height=950');
        }
    };

    const kelleyBlueBookReviewsList = kelleyBlueBookReviews.dataList;
    const hasMore = kelleyBlueBookReviews.hasMore || false;

    let updateReviews = (req) => {
        let currentPage = req.currentSlide;
        let secondToLastPage = kelleyBlueBookReviewsList.length - 2;
        /*
            If there are more reviews to pull, and the user is at the second to the last page,
            add more reviews until all reviews are downloaded
        */
        if(currentPage === secondToLastPage && hasMore === true){
            getDealerReviewsData(svocId, reviewsCountLimit, currentReviewCount)
                .then( (data) => {
                        data.dataList = kelleyBlueBookReviews.dataList.concat(data.dataList);
                        setKelleyBlueBookReviews(data);
                        setMaxReviewCount(maxReviewCount + reviewsCountLimit);
                        setCurrentReviewCount(maxReviewCount);
                    }
                );
        }
        
    };

    if(isAuthenticated && isCoreApiHealthy){
        return (
            <div>
                <RatingSection size={"Medium"} providerName={"KBB.com Dealer Rating"} providerRating={kbbRatings.avgRating} style={{textAlign: "center"}}/>
                <Carousel
                    indicatorType="thumbnail"
                    onStateUpdateCallback={updateReviews}
                >
                    {
                        kelleyBlueBookReviewsList.slice(0, maxReviewCount).map((value, index) => {
                            return(
                                <Box key={value.id}>
                                    {value.reviewerName} <Rating rating={value.rating} size={'XSmall'} text=" " />
                                    {value.reviewText}
                                    <div>{moment(value.reviewDate).fromNow()}</div>
                                </Box>
                            );
                        })
                    }            
                </Carousel>
                <DynamicWidgetButtonDiv>
                    <Button
                        id="see-reviews"
                        buttonType='primary'
                        text='Read Reviews'
                        width='285px'
                        sizeType='large'
                        onClick={handleCTAButton}
                        target="_blank"
                    />
                </DynamicWidgetButtonDiv>
            </div>
        );
    }

    return (
        <div>
          <ErrorText widgetTheme={widgetTheme}>
            { !isCoreApiHealthy ? "A technical error has occurred, please check again later." : "This subscription has expired."}
            
          </ErrorText>
        </div>
    );

}

export default DynamicWidget;