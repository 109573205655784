import React from 'react';
import { Circle, Rectangle, Box} from "@argo/ui-elements";
import { FlexBox } from "@argo/principles";
import colors from '@argo/principles/colors';

const SmallBadgeSkeleton = () => {
  const inverse = true; 
  return ( 
    <div style={{width: "100px", height: "48px", border: `2px solid ${colors.neutral.trueGray}`, borderRadius: "8px", display: "inline-block", backgroundColor: "white"}} >
      <Box withBorder={false} contentPadding="extraSmall" >
        <FlexBox
          verticalSpacing='micro'
          justifyContent="start"
          default={[
            '0 0 50%',
            '1 1 50%'
          ]}
          flexWrap='wrap'>
          <FlexBox default={['1', '1 1 95%']}>
            <div>
              <Circle inverse={inverse} padding='xs' />
            </div>
            <FlexBox default={['0 0 100%']} flexWrap='wrap'>
              <Rectangle inverse={inverse} padding='xs' />
            </FlexBox>
          </FlexBox>
        </FlexBox>
      </Box>
    </div>
  );
}

export default SmallBadgeSkeleton;