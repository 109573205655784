import React, { useContext, useState } from 'react';
import styled from '@emotion/styled';
import Ratings from '../containers/ReviewSummary/Ratings';
import Reviews from '../containers/ReviewSummary/Reviews'
import RatingsAndReviewsContext from '../contexts';
import { postMessagePixallData } from '../helpers/analyticsTaggingHelper';
import createGuid from '../helpers/createGuid';
import { kbbBaseUrl, drrReviewPixall } from '../constants';
import { pageInstanceId } from 'atc-js';


import Button from '@argo/ui-elements/Button';


const ReviewSummary = () => {
  const {
    isAuthenticated,
    isCoreApiHealthy,
    widgetTheme,
    kelleyBlueBookReviews,
    svocId,
    kbbRatings,
    userZip,
  } = useContext(RatingsAndReviewsContext);
  let roundedRating = parseFloat(kbbRatings.avgRating).toFixed(1).toString();
  if(roundedRating === '0.0'){
    roundedRating = 'N/A';
  }

  const eventData = {
    formEventId: createGuid(),
    zipCode: userZip,
    pageInstanceId: pageInstanceId.getRandomId()
  };

  let dealerDDPUrl = `${kbbBaseUrl}\\dealers\\${svocId}`;
  
  const ctastyle = {
    display: 'flex', 
    marginLeft: 'auto',
    
  }

  const kelleyBlueBookReviewsList = kelleyBlueBookReviews.dataList;
  let hasReviews = kelleyBlueBookReviewsList.length !== 0
  const [isScrollBarPresent , setIsScrollBarPresent] = useState(false);
  const widgetBackgroundColor = "#f4f4f4"

  // THIS IS COMMENTED OUT FOR DEALER SYNDICATED WIDGETS
  // event handler to get page height and postmessage to parent page
  // this will be invoked on every page resizing event that happens in the ReviewResponseAccordion component.

  // if (contentArea.current){
    //   window.parent.postMessage({
    //     eventSource: "EmbeddedWidget",
    //     eventAction: "heightChanged",
    //     height: contentArea.current.clientHeight,
    //   }, '*');
    // }
  
  const onAccordionButtonClick = () => {
    setIsScrollBarPresent(document.body.clientHeight > window.innerHeight); // detects if scroll bar is detected in initial iframe height
  };

  const onWriteReviewCTAButtonClick = () => {
      postMessagePixallData('click', 'dealership', svocId, { ...drrReviewPixall, ...eventData, label: 'write-a-review' });
  };

  const onSeeAllReviewCTAButtonClick = () => {
      postMessagePixallData('click', 'dealership', svocId, { ...drrReviewPixall, ...eventData, label: 'see-all-reviews' });
  };

 
const ErrorText = styled.p`
  ${({ widgetTheme }) => 
    widgetTheme ? 
    widgetTheme.typography.defaults.SSemi : ''
  };
  text-transform: none;
  font-size: 16px;
  align-text: center;
  width: 100%;
  max-height: 48px;
  padding: 5px;
`;

// this style is pulled from dealer reviews app.js.  These two headers need to match in style so if you make a change here
// also make a change there: \kbb-dealer-reviews\src\www\src\app\app.js
const TitleText = styled.h1`
  margin: 0;
  padding-bottom: 16px;
  padding-top: 16px;
  text-transform: none;
  font-weight: bold;
  font-size: 24px;
  font-family: Montserrat;
  background-color: #12416F;
  color: white;
  margin-bottom: 16px;
  @media (max-width: 643px) {
    font-size: 18px;
  }
  text-align: center;
`;

const initialfooterStyle = {
  backgroundColor: widgetBackgroundColor,
  paddingTop: "8px",
  paddingBottom: "8px",
}

const scrollFooterStyle = {
  backgroundColor: widgetBackgroundColor,
  position: "sticky",
  bottom: '0',
  paddingTop: "8px",
  paddingBottom: "8px",

}

  return (
    <div style={{backgroundColor: widgetBackgroundColor}}>
      <TitleText data-cy={'syndicatedHeader'}>
        KBB.com Dealer Rating
      </TitleText>
      {isAuthenticated && isCoreApiHealthy ? 
        <div id="embedded-layout" style={{ height: `100%`, display: 'flex', flexDirection: 'row', flexFlow: 'wrap', paddingBottom: "30px", paddingLeft: '16px', paddingRight: '16px'}}>
          <div id='ratings-content' style={{paddingLeft: '3px', paddingBottom: '10px', flex: '1 0 200px'}}>
            <Ratings onWriteReviewButtonClick={onWriteReviewCTAButtonClick}/>
          </div>
          <div id='reviews-content' style ={{ flex: '2 2 200px', width: "100%"}}>
            <Reviews onAccordionButtonClick={onAccordionButtonClick}/>
            {
            hasReviews &&
            <footer style={isScrollBarPresent ? scrollFooterStyle : initialfooterStyle}>
              <Button style={ctastyle}
                buttonType="tertiary"
                id="seeMore"
                text="See All Reviews"
                inverse="true"
                href={dealerDDPUrl}
                target="_blank"
                width='200px'
                enableTitleCase="true"
                onClick={onSeeAllReviewCTAButtonClick}
              />  
            </footer>
            }
          </div>
        </div>
        :
        <div style={{width: '100%'}}>
          <ErrorText widgetTheme={widgetTheme}>
            { !isCoreApiHealthy ? "A technical error has occurred, please check again later." : "This subscription has expired." }            
          </ErrorText>
        </div>
      }
    </div>
  );
}

export default ReviewSummary;