import React from 'react';
import { Circle, Rectangle } from "@argo/ui-elements";
import { FlexBox } from "@argo/principles";
import colors from '@argo/principles/colors';

const HorizontalBadgeSkeletonLoader = () => {
  return ( // skeleton loader.
    <div style={{width: "214px", height: "92px", border: `2px solid ${colors.neutral.trueGray}`, borderRadius: "8px", display: "inline-block", backgroundColor: "white"}}> 
      <FlexBox style={{ marginTop: "12px", marginLeft: "5px", marginRight: "5px"}} default={['0 0 100%']} flexWrap='wrap'>
        <FlexBox default={['1', '1 1 95%']}>
          <div>
            <Circle padding='md' />
          </div>
          <FlexBox default={['0 0 90%', '0 0 90%']} flexWrap='wrap'>
            <Rectangle padding='xs' />
            <Rectangle padding='xs' />
          </FlexBox>
        </FlexBox>
      </FlexBox>
    </div>
  );
}

export default HorizontalBadgeSkeletonLoader;
