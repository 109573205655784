import React from 'react';
import { Helmet } from 'react-helmet';
import { gtmCode } from '../constants/index';

const dataLayer = 'window.dataLayer = window.dataLayer || [];';
const gtmScript = `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start': new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src='//www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);})(window,document,'script','dataLayer','${gtmCode}');`;

const PageHead = () => (
  <Helmet htmlAttributes={{ lang: 'en' }}>
    <script>{dataLayer}</script>
    <script>{gtmScript}</script>
    <link as="style" rel="preload" href="https://fonts.googleapis.com/css?family=Montserrat:500,600,800|Open+Sans:400,600,700&display=swap" />
  </Helmet>
);

export default PageHead;