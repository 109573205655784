export const getDealerRatingsData = async (clientId, noCache, includeReviews) => {
  try {
    return new Promise((resolve, reject) => {
      try {
        // Get data from express server
        var getDealerRatingsDataUrl = `/widget/dealerdata?clientId=${clientId}&nocache=${noCache}&includereviews=${includeReviews}`;
        const options = {
          method: 'get',
          headers: {
            'Content-Type': 'application/json',
          },
        };

        fetch(getDealerRatingsDataUrl, options)
          .then((response) => response.json())
          .then((data) => {
            if (data.data) {
              const result = data.data;
              resolve(result);
            } else {
              let dataErrorMessage = "";
              if (data.errorMessage)
              {
                dataErrorMessage = ` with errorMessage ${data.errorMessage}`;
              }
              const errorMessage = `A technical error occured in getDealerData(clientId: ${clientId}, noCache: ${noCache}, includeReviews ${includeReviews})`;
              reject(errorMessage);
            }
          })
          .catch((error) => {
            const errorMessage = `A technical error occured in getDealerData(clientId: ${clientId}, noCache: ${noCache}, includeReviews ${includeReviews}) with error: (${error})`;            
            reject(errorMessage);
          });
      } catch (error) {
        reject(error);
      }
    });
  }
  catch (e) {
    console.error(e);
    return e.response ? e.response.data : e;
  }
}

export const getDealerReviewsData = async (clientId, limit = 11, offset = 0) => {
  try {
    return new Promise((resolve, reject) => {
      try {
        // Get data from express server
        var getDealerDataUrl = `/widget/dealerreviewsdata?clientId=${clientId}&limit=${limit}&offset=${offset}`;
        const options = {
          method: 'get',
          headers: {
            'Content-Type': 'application/json',
          },
        };

        fetch(getDealerDataUrl, options)
          .then((response) => response.json())
          .then((data) => {
            if (data.data) {
              const result = data.data;
              resolve(result);
            } else {
              let dataErrorMessage = "";
              if (data.errorMessage)
              {
                dataErrorMessage = ` with errorMessage ${data.errorMessage}`;
              }
              const errorMessage = `A technical error occured in getDealerReviewsData(clientId: ${clientId}, limit: ${limit}, offset: ${offset})`;
              reject(errorMessage);
            }
          })
          .catch((error) => {
            const errorMessage = `A technical error occured in getDealerReviewsData(clientId: ${clientId}, limit: ${limit}, offset: ${offset}) with error: (${error})`;            
            reject(errorMessage);
          });
      } catch (error) {
        reject(error);
      }
    });
  }
  catch (e) {
    console.error(e);
    return e.response ? e.response.data : e;
  }
}

export const authenticateClientId = async (clientId) => {
  try {
    return new Promise(async(resolve, reject) => {
      try {
        // Get data from express server
        var authenticateClientIdUrl = `/widget/authenticateClientId?clientId=${clientId}`;
        const options = {
          method: 'get',
          headers: {
            'Content-Type': 'application/json',
          },
        };

        await fetch(authenticateClientIdUrl, options)
          .then((response) => response.json())
          .then((data) => {
            if (data.operationStatus) {
              if(data.operationStatus = 'Successful') {
                const svocId = data.data;
                resolve(svocId); // if authorized, resolve with svocid
              }
              else if(data.operationStatus = 'Unauthorized') {
                resolve(''); // when unauthorized, resolve with an empty string
              }
              else {
                const errorMessage = `A technical error occured in authenticateClientId ${data.ErrorMessage}, clientId: ${clientId}`;
                reject(errorMessage); // if error, resolve with error message
              }
            }
            else {
              const errorMessage = `A technical error occured in authenticateClientId clientId: ${clientId}`;
              reject(errorMessage); // if error, resolve with error message
            }
          })
          .catch((error) => {
            const errorMessage = `A technical error occured in authenticateClientId, clientId: ${clientId} with error: (${error})`;            
            reject(errorMessage);
          });
      } catch (error) {
        reject(error);
      }
    });
  }
  catch (e) {
    console.error(e);
    return e.response ? e.response.data : e;
  }
}

/** Gets the Zip Code based on Latitude and Longitude **/
export const getZipCodeByLatAndLong = async (lat, long) => {
  try {
    return new Promise(async(resolve, reject) => {
      try {
        // Get data from express server
        var getZipCodeByLatAndLongUrl = `/widget/getzipcodebylatng?lat=${lat}&long=${long}`;
        const options = {
          method: 'get',
          headers: {
            'Content-Type': 'application/json',
          },
        };

        fetch(getZipCodeByLatAndLongUrl, options)
          .then((response) => response.json())
          .then((data) => {
            if (data.data) {
              const result = data.data
              resolve(result);
            } else {
              let dataErrorMessage = "";
              if (data.errorMessage)
              {
                dataErrorMessage = ` with errorMessage ${data.errorMessage}`;
              }
              const errorMessage = `A technical error occured in getZipCodeByLatAndLong(lat: ${lat}, long: ${long})`;
              reject(errorMessage);
            }
          })
          .catch((error) => {
            const errorMessage = `A technical error occured in getZipCodeByLatAndLong(lat: ${lat}, long: ${long}) with error: (${error})`;            
            reject(errorMessage);
          });
      } catch (error) {
        reject(error);
      }
    });
  }
  catch (e) {
    console.error(e);
    return e.response ? e.response.data : e;
  }
}