const appConfig = process.env.REACT_APP_CONFIG || 'nonprod';
const kbbBaseUrl = process.env.REACT_APP_KBB_BASE_URL || "https://staging.kbb.com";
const dynamicReviewsCountLimit = process.env.INTERACTIVE_REVIEWS_COUNT_LIMIT || 3;
const isProd = appConfig === 'prod';
const gtmCode = appConfig === 'prod' ? 'GTM-TL58VQ5' : 'GTM-KVH627V'; // lead driver GTM Container Codes
const ratingsBadgeBaseUrl = isProd ? "https://ratings.syndication.kbb.com" : "https://ratings-np.syndication.kbb.com";
const demoPageUrl = isProd ? "https://ratingsdemo.kbb.com" : "https://ratingsdemonp.kbb.com";


const drrReviewPixall = {
    assistChannel: 'KBB',
    assistProduct: 'Dealer Ratings',
    assistSubchannel: 'Dealer Ratings Premium',
    feature: 'Dealer Ratings',
    pageType: 'dealer-ratings',
    pageId: `dealer-ratings_premium_reviews`,
}

const drrPixall = {
    assistChannel: 'KBB',
    assistProduct: 'Dealer Ratings',
    assistSubchannel: 'Dealer Ratings',
    feature: 'Dealer Ratings Premium',
    pageType: 'dealer-ratings',
}

const large = 'large';
const small = 'small';

const kbb = 'kbb';


export {
    gtmCode,
    kbb,
    kbbBaseUrl,
    large,
    small,
    ratingsBadgeBaseUrl,
    demoPageUrl,
    dynamicReviewsCountLimit,
    drrPixall,
    drrReviewPixall
};