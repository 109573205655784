import React from 'react';

const Logo = ({ providerName, size, inline }) => {
  let sz;
  if(inline){
    sz = "18px";
  }else{
    sz = "24px";
  }
  
  switch(providerName.toLowerCase()) { 
    
    case 'kbb.com dealer rating':
    case 'kbb.com':
      return (
        <img src='https://files.awskbbdealerrating.kbb.com/dealerratings/KBBLogoRefresh.svg' alt="KbbLogo" style={{ 
          height: size == "small" ? sz : '40px', 
          paddingTop: size == "small" ? '0px' : '5px', 
          width: size == "small" ? '18px' : '' }}/>
      );
    default:
      return null;
  }
};

export default Logo;
