import React, {useContext} from 'react';
import FlexBox from '@argo/principles/FlexBox';
import Rating from '@argo/ui-elements/Rating';
import styled from '@emotion/styled';
import Logo from './Logo';
import RatingsAndReviewsContext from '../contexts';


const TitleText = styled.p`
  ${({ widgetTheme }) => 
    widgetTheme ? 
    widgetTheme.typography.defaults.XL : ''
  };
`;
const SquareRatingText = styled.p`
  padding-top: 14px;
  padding-left: 4px;
  font-size: 36px;
  font-family: 'Montserrat';
  font-weight: 800;
`;
const SmallText = styled.p`
  ${({ widgetTheme }) => 
    widgetTheme ? 
    widgetTheme.typography.defaults.SExtra : ''
  };
`;
const displayStarRatingsImage = (ratingsType, size, roundedRating, maxRating, noStarRatingWidth, starRatingsWidth) => {
  const ratingsTypeLowerCase = ratingsType ? ratingsType.toLowerCase() : 'null';
  switch(ratingsTypeLowerCase){
    case 'square':
      return(
        <img src={'https://files.awskbbdealerrating.kbb.com/dealerratings/Star.svg'}  alt="RatingsStarLogo"  style={{ 
          height: '24px', 
          paddingTop: '4px'}}/>
      );
    case 'small':
      return (
        <img src={'https://files.awskbbdealerrating.kbb.com/dealerratings/Star.svg'}  alt="RatingsStarLogo"  style={{ 
          height: '18px'}}/>
      );
    default:
      return(
        <div style={{ width: roundedRating === 'N/A' ? noStarRatingWidth : starRatingsWidth }}>
          <Rating 
          size={size}
          rating={roundedRating}
          maxRating={maxRating}
          />
        </div>        
      );
  }
}
const displayRatingsText = (ratingsType, widgetTheme, roundedRating) => {
  const ratingsTypeLowerCase = ratingsType ? ratingsType.toLowerCase() : 'null';
  switch(ratingsTypeLowerCase){
    case 'small':
      return(
        <SmallText id='syndicatedRating' style={{paddingRight: '1px'}} widgetTheme={widgetTheme}>
          {roundedRating}
        </SmallText>
      );
    case 'square':
      return(
        <SquareRatingText style={{paddingLeft: '1px', marginTop: "-10px"}} id='syndicatedRating'>
          {roundedRating}
        </SquareRatingText>      
      )
    default:
      return(
        <TitleText id='syndicatedRating' style={{paddingRight: '10px'}} widgetTheme={widgetTheme}>
          {roundedRating}
        </TitleText>
      );
  }
}

const RatingSection = ({ size = 'Medium', providerName, providerRating, maxRating = 5, starRatingsWidth="140px", noStarRatingWidth="121px", displayRatingText=true, ratingsType }) => {
  const { widgetTheme } = useContext(RatingsAndReviewsContext);

  // Rounding logic.
  if (!providerRating)
  {
    providerRating = 0;
  }

  if (ratingsType == "small"){
    starRatingsWidth= "18px"
  }

  // This adds a 0 to a rating if it a whole number. For example 4 would display as 4.0
  let roundedRating = parseFloat(providerRating).toFixed(1);

  // if ratings is 0.0, change to display N/A instead
  if(roundedRating === '0.0') {
    roundedRating = 'N/A';
  }

  return (
    <div style={{ textAlign: "center" }}>
    <FlexBox 
      alignItems='center' 
      verticalSpacing='micro' 
      horizontalSpacing='micro' 
      style={{ paddingBottom: '8px', paddingTop: "5px" }}  
      justifyContent="center"
    >
      <div>
        <Logo providerName={providerName} />
      </div>
      {
        displayRatingText && displayRatingsText(ratingsType, widgetTheme, roundedRating)
      }
      {
        displayStarRatingsImage(ratingsType, size, roundedRating, maxRating, noStarRatingWidth, starRatingsWidth)
      }
    </FlexBox>
    </div>
  );
};

export default RatingSection;
