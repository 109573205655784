import React from 'react';


const Logo = ({ providerName, size, inline }) => {
  let sz;
  if(inline){
    sz = "18px";
  }else{
    sz = "24px";
  }
  
  switch(providerName.toLowerCase()) { 
    
    case 'kbb.com dealer rating':
    case 'kbb.com':
      return (
        <img src={"https://files.awskbbdealerrating.kbb.com/dealerratings/KBBLogoRefresh.svg"} alt="KbbLogo" style={{ 
          height: size == "small" ? sz : '65px', 
          paddingTop: size == "small" ? '0px' : '20px', 
          width: size == "small" ? '18px' : '' }}/>
      );
    case 'small dealer ratings':
      return (
        <img src={"https://files.awskbbdealerrating.kbb.com/dealerratings/KBBLogoSeal-Small.svg"} alt="KbbSmallLogo" style={{ 
          height: size == "small" ? sz : '34px', 
          paddingTop: size == "small" ? '0px' : '3px', 
          width: size == "small" ? '18px' : '' }}/>
      );
    case 'kbb.com square dealer ratings badge':
      return (
        <img src={"https://files.awskbbdealerrating.kbb.com/dealerratings/KBBLogoRefresh.svg"} alt="KbbLogo" style={{ 
          height: '65px', 
          paddingLeft: '16px'}}/>
      );
      case 'kbb.com horizontal dealer ratings badge':
        return (
          <img src={"https://files.awskbbdealerrating.kbb.com/dealerratings/KBBLogoRefresh.svg"} alt="KbbLogo" style={{ 
            height: '55px', 
            paddingTop: '16px', 
            paddingLeft: '16px',
            paddingBottom: '16px'}}/>
        );
    default:
      return null;
  }
};

export default Logo;
