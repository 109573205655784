import React from 'react';
import styled from '@emotion/styled';
import typography from '@argo/principles/typography';
import colors from '@argo/principles/colors';
import size from '@argo/principles/constants/size';

const PopoverBackground = styled.div`
  position: fixed;
  // background: rgba(0, 0, 0, 0.5);
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 101;
`;

const PopoverContainer = styled.div(props => `
  background-color: ${colors.primary.charcoal};
  max-width: 230px;
  left: 150px;
  border-radius: ${size.micro}px;
  z-index: 1;
  position: relative;

  // this will automatically center the popover base on the window height
  // if this widget is directly embedded in code instead of using iframe, the behaivor will need to be adjusted
  ${props.centerVertical
  ? `
    top: 50%;
    transform: translate(-50%, -50%);
  ` : `
    top: ${props.coordinates.y + 35}px;
    transform: translate(-50%);
  `
  };
  
  ${props.showArrow
  ? `
    &:after {
    content: " ";
    position: absolute;
    bottom: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 10px;
    border-style: solid;
    border-color: transparent transparent ${colors.primary.charcoal} transparent;
  ` : `
  `
  };
`);

const PopoverBody = styled.div`
  color: ${colors.primary.white};
  font-family: ${typography.family.opensans};
  font-size: 14px;
  font-weight: ${typography.weight.regular};
  letter-spacing: 0;
  padding: 12px 12px 12px 12px;
`

const Popover = ({ isShown, onClose, textContent, coordinates, showArrow, centerVertical }) => {
  // allow popover to be closed by clicking anywhere on the screen
  const handleClickOutside = event => {
    onClose();
  }


  return isShown ? (
    <PopoverBackground
      onClick={handleClickOutside}
    >
      <PopoverContainer id='popover-container' 
        coordinates={coordinates}
        showArrow={showArrow}
        centerVertical={centerVertical}
      >
        <PopoverBody>
          <span id='popover-text'>
            {textContent}
          </span>
        </PopoverBody>
      </PopoverContainer>
    </PopoverBackground>
  ) : null
}

export default Popover;
