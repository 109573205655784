import Button from '@argo/ui-elements/Button';
import Rating from '@argo/ui-elements/Rating';
import styled from '@emotion/styled';
import { pageInstanceId } from 'atc-js';
import React, { useContext } from 'react';
import { drrPixall } from '../constants';
import RatingsAndReviewsContext from '../contexts';
import { postMessagePixallData } from '../helpers/analyticsTaggingHelper';
import createGuid from '../helpers/createGuid';

const ErrorText = styled.p`
  ${({ widgetTheme }) => (widgetTheme ? widgetTheme.typography.defaults.SSemi : '')};
  text-transform: none;
  font-size: 16px;
  text-align: center;
`;

const FallbackText = styled.p`
  ${({ widgetTheme }) => (widgetTheme ? widgetTheme.typography.defaults.SSemi : '')};
  text-transform: none;
  font-size: 11px;
  text-transform: none;
  text-align: center;
  margin-right: 10px;
`;

const LeaderboardRatings = () => {
  const {
    svocId,
    kbbRatings,
    widgetTheme,
    isAuthenticated,
    isCoreApiHealthy,
    optionalDedicatedPageUrl,
    clientId,
    userZip,
  } = useContext(RatingsAndReviewsContext);

  const originUrl =
    window.location !== window.parent.location ? document.referrer : document.location.href;
  const isRatingAvailable = kbbRatings.avgRating > 0.0;
  let roundedRating = parseFloat(kbbRatings.avgRating).toFixed(1).toString();

  if (roundedRating === '0.0') {
    roundedRating = 'N/A';
  }

  const eventData = {
    formEventId: createGuid(),
    zipCode: userZip,
    label: `rating::kbb::${roundedRating}`,
    pageInstanceId: pageInstanceId.getRandomId(),
    pageId: `dealer-ratings_premium_${originUrl}`,
  };

  let ctaUrl = '';
  if (optionalDedicatedPageUrl) {
    ctaUrl = optionalDedicatedPageUrl;
  } else {
    ctaUrl = `/syndicated-widget/review/clientid/${clientId}`;
  }

  const handleCTAButton = () => {
    postMessagePixallData('click', 'dealership', svocId, {
      ...drrPixall,
      ...eventData,
    });

    if (optionalDedicatedPageUrl) {
      window.top.location.href = optionalDedicatedPageUrl;
    } else {
      window.open(ctaUrl, 'popup', 'width=800,height=950');
    }
  };

  return (
    <div id='container'>
      {isAuthenticated && isCoreApiHealthy ? (
        <div className='leaderboard-container' onClick={handleCTAButton}>
          <p className='leaderboard-text'>See what our customers are saying.</p>
          <Button
            id='read-reviews-btn'
            buttonType='primary'
            text='Read Reviews'
            width='225px'
            sizeType='small'
            onClick={handleCTAButton}
            target='_blank'
            fontSizeOverride='18'
          />
          {isRatingAvailable ? (
            <div className='rating-container'>
              <div className='left-rating-container'>
                <img
                  src='https://files.awskbbdealerrating.kbb.com/dealerratings/KBBLogoRefresh.svg'
                  className='kbb-logo'
                  alt='KBB Logo'
                  width='51px'
                  height='69px'
                />
              </div>
              <div className='right-rating-container'>
                <div className='rating-count-container'>
                  <p className='rating-number'>{roundedRating}</p>
                  <div className='rating-stars-desktop'>
                    <Rating size='Medium' rating={roundedRating} maxRating={5} />
                  </div>
                  <div className='rating-stars-mobile'>
                    <Rating size='Medium' rating={roundedRating} maxRating={1} />
                  </div>
                </div>
                <p className='rating-desc-text'>You can rate and review this dealer.</p>
              </div>
            </div>
          ) : (
            <FallbackText widgetTheme={widgetTheme}>Rating Not Available</FallbackText>
          )}
        </div>
      ) : (
        <div className='error-container'>
          <ErrorText widgetTheme={widgetTheme}>
            {!isCoreApiHealthy
              ? 'A technical error has occurred, please check again later.'
              : 'This subscription has expired.'}
          </ErrorText>
        </div>
      )}
    </div>
  );
};

export default LeaderboardRatings;
