import React from 'react';
import { Circle, Rectangle } from "@argo/ui-elements";
import { FlexBox } from "@argo/principles";

const SkeletonLoader = () => {
  return ( // skeleton loader.
  <div> 
    <FlexBox style={{ margin: "5px" }}  default={['0 0 100%']} flexWrap='wrap'>
      <FlexBox default={['1', '95%']}>
        <Rectangle padding='xs' />
      </FlexBox>
    </FlexBox>    
    <FlexBox style={{ margin: "5px" }}  default={['0 0 100%']} flexWrap='wrap'>
      <FlexBox default={['1', '1 1 95%']}>
        <div>
          <Circle padding='md' />
        </div>
        <FlexBox default={['0 0 95%', '0 0 95%']} flexWrap='wrap'>
          <Rectangle padding='xs' />
          <Rectangle padding='xs' />
        </FlexBox>
      </FlexBox>
    </FlexBox>
</div>
  );
}

export default SkeletonLoader;