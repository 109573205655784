import React from 'react';
import ServiceRepair from '@argo/ui-elements/icons/ServiceRepairIcon';
import styled from '@emotion/styled';
import { breakpoints, FlexBox } from "@argo/principles";
import Image from '@argo/ui-elements/Image';
const cdnBaseUrl = process.env.CDN_BASE_URL || "https://files.awskbbdealerrating.kbb.com/dealerratings";

const CustomTextContainer= styled(FlexBox)`
    margin-right: 0px;
    text-align:  ${({ inHeader }) =>
      inHeader ? 'center' : ''};
  
    @media (max-width: ${breakpoints.md}px) {
      display: none;
      margin: 0;
      margin-left: 0px;
      margin-right: 0px;
    }
`;

const CustomText= styled('div')`
    @media (max-width: ${breakpoints.md}px) {
      display: none;
      margin: 0;
      margin-left: 0px;
      margin-right: 0px;
    }

    @media (min-width: ${breakpoints.md+1}px) {
      margin-left: 5px;
      margin-right: 10px;
    }

    padding-top: 1px;

    color: #505050;
`;

const CustomContainer = styled(FlexBox)`
    @media (max-width: ${breakpoints.md}px) {
      ${({ inHeader }) => {
          if (!inHeader){
            return "margin: -16px";
          }
        }
      }
    }

    @media (min-width: ${breakpoints.md+1}px) {
      ${({ inHeader }) => {
          if (!inHeader){
            return "margin: -14px";
          }
        }
      }
    }

`;

const ReviewTypeTab = ({ text, icon, inHeader }) => {
  return <CustomContainer alignItems="center"  inHeader={inHeader}>
          {
            icon == "Service"
              ? <ServiceRepair size="16" style={{marginRight: '0px'}}/>
              : <Image width="16" src={cdnBaseUrl + '/PriceIcon.svg'} style={{ marginRight: '0px' }}/>
          }
          <CustomTextContainer inHeader={inHeader} alignContent="flex-start">
            <CustomText >{text}</CustomText>
          </CustomTextContainer>
        </CustomContainer>
};

export default ReviewTypeTab;
