import React, { useContext } from 'react';
import RatingsAndReviewsContext from '../../contexts';
import RatingSection from './RatingSection';
import PopoverLink from './PopoverLink';
import Divider from '@argo/ui-elements/Divider';
import styled from '@emotion/styled';
import { kbbBaseUrl } from '../../constants';
import Button from '@argo/ui-elements/Button';

const Text = styled.p`
  ${({ widgetTheme }) => 
    widgetTheme ? 
    widgetTheme.typography.defaults.SSemi : ''
  };
  margin: 0;
  padding-bottom: 8px;
  text-transform: none;
`;

const Ratings = ({onWriteReviewButtonClick}) => {
  const {
    queryParameters,
    reviewEligible,
    kbbRatings,
    widgetTheme,
    dealerId,
    svocId
  } = useContext(RatingsAndReviewsContext);

  //  add commas to the appropriate place. i.e. 4,234
  let intl = Intl.NumberFormat();

  let kbbReviewCountText = '';
  if(kbbRatings && kbbRatings.count != 0) {
    kbbReviewCountText = `(${intl.format(kbbRatings.count)} Review`;
    if(kbbRatings.count !== 1)
    {
      kbbReviewCountText += 's';
    }
    kbbReviewCountText+=')';
  }

  const dealershipId = dealerId;
  const encodedDealershipId = btoa(dealershipId);
  const baseCtaUrl = `${kbbBaseUrl}/syndicated/write-review?dealerId=${encodedDealershipId}&svocId=${svocId}&origin=dealersyndicatedwidget`
  const originParam = queryParameters?.origin;
  const ctaUrl = originParam !== undefined && originParam.length > 0 
                ? baseCtaUrl + `&origin=${originParam}` : baseCtaUrl;

  let button = null;


  return (
    <div id='ratings-container'>
      <Text widgetTheme={widgetTheme}>
        Ratings
      </Text>
      <RatingSection size={"large"} providerName={"KBB.com Dealer Rating"} providerRating={kbbRatings.avgRating} reviewsCountText={kbbReviewCountText}/>
      <div style={{paddingTop: '5px'}}>
        <PopoverLink scrollIntoView={false} showArrow={false} centerVertical={false} paddingBottom={'10px'} textOverride={'How is this KBB.com rating calculated?'}/>
      </div>
      { {reviewEligible} ?
        <div>
          <Divider style={{marginBottom: '16px', marginTop:'8px'}}/>
          <Button
          id="write-a-review"
          buttonType='secondary'
          text='Write a Review'
          width='100%'
          sizeType='large'
          href={ctaUrl}
          onClick={onWriteReviewButtonClick}
          />
        </div>
      : 
        ''
      }
    </div>
  );
};

export default Ratings;