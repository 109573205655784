import React, {useContext} from 'react';
import FlexBox from '@argo/principles/FlexBox';
import Rating from '@argo/ui-elements/Rating';
import styled from '@emotion/styled';
import Logo from './Logo';
import RatingsAndReviewsContext from '../../contexts';
import { large, small } from '../../constants/index';

const TitleText = styled.p`
  ${({ widgetTheme }) => 
    widgetTheme ? 
    widgetTheme.typography.defaults.XL : ''
  };
  margin-top: -5px;
`;

const BoldText = styled.p`
  ${({ widgetTheme }) => 
    widgetTheme ? 
    widgetTheme.typography.defaults.XSSemi : ''
  };
  margin: 0;
`;

const Text = styled.p`
  ${({ widgetTheme }) => 
    widgetTheme ? 
    (`${widgetTheme.typography.defaults.body12}; ${widgetTheme.typography.fontColorDefaults.body}`) : ''
  };
  margin: 0;
`;

const RatingSection = ({ size = small, providerName, providerRating, reviewsCountText, layout }) => {
  const { widgetTheme } = useContext(RatingsAndReviewsContext);

  // Rounding logic
  if (!providerRating)
  {
    providerRating = 0;
  }

  // This adds a 0 to a rating if it a whole number. For example 4 would display as 4.0
  let roundedRating = parseFloat(providerRating).toFixed(1);
  let showReviewsCountText = true;

  // if ratings is 0.0, change to display N/A instead
  if(roundedRating === '0.0') {
    roundedRating = 'N/A';
  }

  if (!reviewsCountText && providerName == "KBB.com"){
    showReviewsCountText = false;
  } 
  
  if (size.toLowerCase() === large) {
    return (
      <FlexBox alignItems='top' verticalSpacing='micro' horizontalSpacing='micro' style={{ paddingBottom: '10px' }}>
        <Logo providerName={providerName} />
        <TitleText id='syndicatedRating' style={{paddingRight: '15px'}} widgetTheme={widgetTheme}>
          {roundedRating}
        </TitleText>
        <div>
        <Rating rating={roundedRating}/>
          <div style={{ display: 'flex', flexDirection: 'row', flexFlow: 'wrap', alignItems: 'center' }}>
            <BoldText widgetTheme={widgetTheme} style={{ marginRight: '7px' }}>
              {providerName}
            </BoldText>
            {
              showReviewsCountText &&
              <Text id='reviewCount' widgetTheme={widgetTheme}>
                {reviewsCountText}
              </Text>
            }
          </div>
        </div>
      </FlexBox>
    );
  }

  return (
    <FlexBox alignItems='center' verticalSpacing='micro' horizontalSpacing='xs' sm={[0,0]} style={{ paddingBottom: '10px' }}>
      <Logo providerName={providerName} />
      <BoldText widgetTheme={widgetTheme}>
        {roundedRating}
      </BoldText>
      <BoldText widgetTheme={widgetTheme}>
        {providerName}
      </BoldText>
      {
        showReviewsCountText &&
        <Text id='reviewCount' widgetTheme={widgetTheme}>
          {reviewsCountText}
        </Text>
      }
    </FlexBox>
  );
};

export default RatingSection;
