import React, { useContext } from 'react';
import RatingsAndReviewsContext from '../contexts';
import RatingSection from '../components/RatingSection';
import colors from '@argo/principles/colors';
import styled from '@emotion/styled';
import { postMessagePixallData } from '../helpers/analyticsTaggingHelper';
import createGuid from '../helpers/createGuid';
import { drrPixall } from '../constants';
import { pageInstanceId } from 'atc-js';

const ErrorText = styled.p`
  ${({ widgetTheme }) => 
    widgetTheme ? 
    widgetTheme.typography.defaults.SSemi : ''
  };
  text-transform: none;
  font-size: 8px;
  align-text: center;
  max-width: 90px;
  max-height: 48px;
  padding: 5px;
`;

const SmallRatings = () => {
  const {
    svocId,
    kbbRatings,
    widgetTheme,
    isAuthenticated,
    isCoreApiHealthy,
    optionalDedicatedPageUrl,
    clientId,
    userZip,
  } = useContext(RatingsAndReviewsContext);
  const originUrl = (window.location !== window.parent.location) ? document.referrer : document.location.href;
  let roundedRating = parseFloat(kbbRatings.avgRating).toFixed(1).toString();
  if(roundedRating === '0.0'){
    roundedRating = 'N/A';
  }

  const eventData = {
    formEventId: createGuid(),
    zipCode: userZip,
    label: `rating::kbb::${roundedRating}`,
    pageInstanceId: pageInstanceId.getRandomId(),
    pageId: `dealer-ratings_premium_${originUrl}`,
  };

  let ctaUrl = '';
  if (optionalDedicatedPageUrl){
    ctaUrl = optionalDedicatedPageUrl;
  } else {
    ctaUrl = `/syndicated-widget/review/clientid/${clientId}`;
  }

  const handleCTAButton = () => {
    postMessagePixallData('click', 'dealership', svocId, { ...drrPixall, ...eventData });

    if (optionalDedicatedPageUrl){
      window.top.location.href = optionalDedicatedPageUrl;
    } else {
      window.open(ctaUrl,'popup','width=800,height=950');
    }
  };

  return (
    <div id='ratings-container' style={{textAlign: "center", width: "100px", height: "48px", border: `2px solid ${colors.neutral.trueGray}`, borderRadius: "8px", display: "inline-block", backgroundColor: "white"}}>
      {isAuthenticated && isCoreApiHealthy ? 
        <div className="flex-container" style={{minWidth: "100px"}}>
            <div id='smallRating' onClick={handleCTAButton} style={{cursor:'pointer'}}>
          <RatingSection size={"small"}  maxRating={1} providerName={"Small Dealer Ratings"} providerRating={kbbRatings.avgRating} style={{textAlign: "center"}} ratingsType='small' starRatingsWidth='18px' />
          </div>
        </div> 
      : 
        <div>
          <ErrorText widgetTheme={widgetTheme}>
            { !isCoreApiHealthy ? "A technical error has occurred, please check again later." : "This subscription has expired."}
            
          </ErrorText>
        </div>
      }
    </div>
  );
}

export default SmallRatings;