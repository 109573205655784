// capitalize the first letter of every word in the text
export const capitalizeFirstLetters = (text) => {
  if(text && text.length > 0) {
    let words = text.split(' ');
    words = words.map(word => {
      return word.charAt(0).toUpperCase() + word.slice(1)
    });
    return words.join(' ');
  }
  return '';
};