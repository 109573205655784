// This component was pulled down for argo because the current
// design did not match the design for kdr overlay. We made 
// modifications to this component locally to fulfill the 
// requirements for a review and response accordion

/* eslint-disable react-hooks/exhaustive-deps */

import React, {
    useState,
    createRef,
    useEffect,
    useCallback,
    useContext,
  } from 'react';
  import styled from '@emotion/styled';
  import { css } from '@emotion/core';
  import colors from '@argo/principles/colors';
  import Link from '@argo/ui-elements/Link';
  import ChevronDownIcon from '@argo/ui-elements/icons/ChevronDownIcon';
  import { activeState, focusState } from '@argo/utils/styleUtils';
  import RatingsAndReviewsContext from '../../contexts';
  import { capitalizeFirstLetters } from '../../helpers/textHelper';

  import FlexBox from '@argo/principles/FlexBox';
  import ReviewTypeTab from './ReviewTypeTab';

  // add svgs
  import ProfileIcon from '../../images/ProfileIcon.svg';
  import SubIndent from '../../images/SubIndent.svg';
  
  // import star
  import Rating from '@argo/ui-elements/Rating';
  
  const accordionContainedStyle = (props) => css`
    background-color: ${props.isInverse
      ? colors.primary.offWhite
      : colors.primary.white};
    border-radius: 4px;
    ${props.isInverse
      ? ""
      : "border: 1px solid " + colors.neutral.stoneGray + ";"}
  `;
  
  const accordionUncontainedStyle = css`
    border-style: solid;
    border-color: ${colors.neutral.stoneGray};
    border-width: 1px 0 1px 0;
  `;
  
  const AccordionWrapper = styled.div`
    align-items: center;
    margin-bottom: 8px;
  
    ${({ accordionDisplayType }) =>
      accordionDisplayType === 'contained'
        ? accordionContainedStyle
        : accordionUncontainedStyle}
  `;
  
  const StyledChevron = styled.div`
    align-items: center;
    background-color: ${colors.primary.offWhite};
    border-radius: 50%;
    display: flex;
    height: 24px;
    flex: 0 0 24px;
    justify-content: center;
    transition: transform 0.4s;
    transform: ${({ stateOpen }) =>
      stateOpen === true ? 'rotate3d(0,0,1,-180deg)' : 'none'};
    width: 24px;
  `;
  
  const AccordionSection = styled.div`
    align-items: flex-start;
    display: flex;
    flex-grow: 1;
    padding: 16px 12px;
  `;

  
  let badgeWidth = 64; //width of the service and sales badges
  let iconsWidth = 142; //142: width accounting for icon, star icon, and rating
  
  // max-width is used to prevent a long label text from overflowing (issue currently exists in argo accordion)
  // 142px was determined by the space NOT occupied by the icon / rating / chevron. This is to accomodate if the widget changes
  // TODO: in the future we should find a better way to prevent overflow without cutting off characters. But this is a good first step by aligning the rating / chevron
  // When this component is moved to argo, this logic will need to be cleaned up to be backwards compatible.
  const getStyledAccordionLabel = (tagName, maxWidth, widgetTheme, accordionFeatureFlagAddSubcategoryService, stateOpen ) => styled[tagName]`
    color: ${colors.primary.black};
    margin: 0 0 0 12px;
    align-self: center;
    white-space: nowrap;
    max-width: ${() => {
      let contentWidth = accordionFeatureFlagAddSubcategoryService 
                          ? stateOpen ? iconsWidth : badgeWidth + iconsWidth 
                          : iconsWidth;
      let difference = maxWidth - contentWidth;
      return difference + 'px';
    }};
    
    overflow: hidden;

    ${({ accordionDisplayType }) =>
    
      accordionDisplayType === 'contained'
        ? widgetTheme.typography.types.SSemi
        : widgetTheme.typography.types.XSSemi};

    text-transform: none;

    width: 100%;

    // logic to fade out text at the end of the div
    background-image: linear-gradient(90deg,#000000 85%,rgba(0,0,0,0));
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
  `;
  
  const ContentAreaContainer = styled.div`
    overflow: hidden;
    max-height: ${({ isInTransition, stateOpen, currentHeight }) =>
      stateOpen
        ? `${isInTransition ? `${currentHeight}px` : 'none'}`
        : `${isInTransition ? `${currentHeight}px` : '0'}`};
    ${({ resetHeight }) => (resetHeight ? 'max-height: 0' : '')};
    transition: max-height 0.5s ease;
  `;
  
  const ContentArea = styled.div`
    padding-bottom: ${({ padding }) =>
      `${(padding && padding.paddingBottom) || '24'}px`};
    padding-left: ${({ padding }) =>
      `${(padding && padding.paddingLeft) || '16'}px`};
    padding-right: ${({ padding }) =>
      `${(padding && padding.paddingRight) || '16'}px`};
    padding-top: ${({ padding }) =>
      `${(padding && padding.paddingTop) || '5'}px`};
  
    ${({ widgetTheme }) => 
      widgetTheme ? 
      widgetTheme.typography.types.body : ''
    };
    & :focus {
      outline: none;
    }
  `;
  
  const AccordionHeaderButton = styled.button`
    align-items: baseline;
    background-color: transparent;
    border: 0;
    cursor: pointer;
    display: flex;
    text-align: left;
    padding: 0;
    width: 100%;
  
    & :hover {
      border-radius: 4px;
      ${({ accordionDisplayType }) =>
        accordionDisplayType === 'contained'
          ? `background-color: ${colors.neutral.doveGray};`
          : ''};
  
      .style-chevron {
        background-color: transparent;
      }
  
      .accordion-label {
        ${({ accordionDisplayType }) =>
          accordionDisplayType === 'contained'
            ? ''
            : `color: ${colors.action.darkBrightBlue};
        `}
      }
    }
    ${activeState}
    ${focusState}
  `;
  
  const RightLink = styled.div`
    flex-shrink: 0;
    margin-left: auto;
    margin-right: 8px;
  `;

  const BoldText = styled.div`
    ${({ widgetTheme }) => 
      widgetTheme ? 
      widgetTheme.typography.defaults.XSSemi : ''
    };
  `;
  
  const Accordion = props => {
    const {
      accordionDisplayType,
      accordionButtonId,
      accordionContentId,
      accordionLabel,
      accordionLabelTag,
      maxWidthElementId,
      children,
      isInverse,
      isOpen = false,
      rightLinkLabel,
      padding,
      starRating,
      hasServiceRating,
      hasSalesRating,
      timeStamp,
      accordionSubTextLabel,
      accordionSubTextList,
      // event handlers
      onRightLinkClick,
      enableTitleCase,
      onAccordionButtonClick,
      ...rest
    } = props;

    const {
      featureFlagAddSubcategoryService,
      queryParameters
    } = useContext(RatingsAndReviewsContext);


  let accordionFeatureFlagAddSubcategoryService = featureFlagAddSubcategoryService;
  if (queryParameters?.setfeatureflagaddsubcategoryservice)
  {
    if (queryParameters.setfeatureflagaddsubcategoryservice == "true"){
      accordionFeatureFlagAddSubcategoryService = true;
    } else if (queryParameters.setfeatureflagaddsubcategoryservice == "false"){
      accordionFeatureFlagAddSubcategoryService = false;
    }
  }  
  
    const [stateOpen, setStateOpen] = useState(isOpen);
    const [isInTransition, setInTransition] = useState(false);
    const [resetHeight, setResetHeight] = useState(false);
    const [contentAreaHeight, setContentAreaHeight] = useState(0);
    const contentArea = createRef();
    const headerButtonRef = createRef();
    const contentRef = createRef();

    // set maxWidth to determine rating and chevron allignment
    let maxWidth;
    if (maxWidthElementId) {
      maxWidth = document.getElementById(maxWidthElementId)?.getBoundingClientRect().width;
    }
    if (!(maxWidth) || maxWidth === 0) {
      maxWidth = window.innerWidth
    }

    const labelText = enableTitleCase
      ? capitalizeFirstLetters(accordionLabel)
      : accordionLabel;

    const { widgetTheme } = useContext(RatingsAndReviewsContext);
  
    const handleAccordionClick = useCallback(() => {
      setStateOpen(!stateOpen);
      setInTransition(true);
  
      // Trigger second render in different frame
      setTimeout(() => {
        setResetHeight(stateOpen);
      }, 0);
    }, [stateOpen]);
  
    useEffect(() => {
      setContentAreaHeight((contentArea.current || {}).scrollHeight || 0);
    });
  
    useEffect(() => {
      if (contentArea.current) {
        contentArea.current.addEventListener('transitionend', () => {
          onAccordionButtonClick();
          setInTransition(false);
        });
      }
    }, []);
  
    // updated accordion label
    const AccordionLabel = getStyledAccordionLabel(accordionLabelTag, maxWidth, widgetTheme, accordionFeatureFlagAddSubcategoryService, stateOpen);
    return (
      <AccordionWrapper {...{ isInverse, accordionDisplayType, padding }}>
        <AccordionHeaderButton
          {...(accordionButtonId && { id: accordionButtonId })}
          {...(accordionContentId && { 'aria-controls': accordionContentId })}
          aria-expanded={stateOpen}
          onClick={handleAccordionClick}
          {...{ isInverse, accordionDisplayType }}
          ref={headerButtonRef}
          {...rest}>
          <AccordionSection>
            <img src={ProfileIcon} alt="ProfileIcon" style={{ height: '24px' }}/>
            <AccordionLabel
              className="accordion-label"
              accordionDisplayType={accordionDisplayType}>
              {labelText}
            </AccordionLabel>
            <div style={{display:'inherit', marginLeft: 'auto'}}>
              {
                hasSalesRating &&
                <ReviewTypeTab text="Sales" icon="Sales" inHeader={true}></ReviewTypeTab>
              }
              {
                hasServiceRating &&
                <ReviewTypeTab text="Service" icon="Service" inHeader={true}></ReviewTypeTab>
              }
            </div>
            <div style={{display:'inherit', marginRight:'0', height:'24px'}}>
              <div style={{display:'inherit', paddingTop:'auto', paddingBottom:'auto'}}>
                <Rating rating={starRating} size={'XSmall'} text=" " />
              </div>
            </div>
            <StyledChevron
              className="style-chevron"
              {...{ isInverse, accordionDisplayType, stateOpen }}>
              <ChevronDownIcon color="darkBrightBlue" size="16" />
            </StyledChevron>
          </AccordionSection>
          {accordionDisplayType === 'uncontained' && rightLinkLabel !== '' && (
            // Prevent action click bubble up to the right link cause opening/closing accordion
            <RightLink onClick={e => e.stopPropagation()}>
              <Link as="div" onClick={onRightLinkClick} type="small">
                {rightLinkLabel}
              </Link>
            </RightLink>
          )}
        </AccordionHeaderButton>
        <ContentAreaContainer
          {...(accordionContentId && { id: accordionContentId })}
          {...(accordionButtonId && { 'aria-labelledby': accordionButtonId })}
          currentHeight={contentAreaHeight}
          isInTransition={isInTransition}
          ref={contentArea}
          resetHeight={resetHeight}
          stateOpen={stateOpen}>
          <ContentArea widgetTheme={widgetTheme} ref={contentRef} tabIndex={-1} padding={padding}>
            {children}
            <FlexBox alignItems="center" justifyContent="end" style={{marginTop: "5px"}}>
              <BoldText widgetTheme={widgetTheme} style={{lineHeight:'16px', textAlign: "right"}}>
                {timeStamp}
              </BoldText>
            </FlexBox>
            {accordionSubTextList.map((obj, index) => {
              let subTextLabel = labelText;
              if (obj.displayaccordionSubTextLabel)
              {
                subTextLabel = accordionSubTextLabel;
              }
              
              return (
                <div style={{display:'flex', paddingTop:'5px', paddingLeft:'2px'}} key={index}>
                  <img src={SubIndent} alt="SubIndent" style={{ height: '24px'}}/>
                  <div style={{paddingLeft:'5px'}}>
                    <div style={{display:'flex', paddingTop:'10px'}}>
                      <img src={ProfileIcon} alt="ProfileIcon" style={{ height: '24px' }}/>
                      <BoldText widgetTheme={widgetTheme} style={{ paddingLeft:'12px'}}>
                        {subTextLabel}
                      </BoldText>
                    </div>
                    <div style={{paddingTop:'5px'}}>
                      {obj.accordionSubText}
                    </div>
                  </div>               
                </div>
                );
            })}
          </ContentArea>
        </ContentAreaContainer>
      </AccordionWrapper>
    );
  };
  
  Accordion.displayName = 'Accordion';
  
  Accordion.defaultProps = {
    isOpen: false,
    isInverse: false,
    accordionLabel: 'Accordion Label',
    accordionLabelTag: 'div',
    maxWidthElementId: null,
    accordionDisplayType: 'contained',
    rightLinkLabel: '',
    onRightLinkClick: undefined,
    enableTitleCase: true,
    starRating: 0,
    timeStamp: '',
    accordionSubTextLabel: '',
    accordionSubTextList: [],
    onAccordionButtonClick: () => {}
  };
  
  export default Accordion;