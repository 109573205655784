import React, { useContext } from 'react';
import Button from '@argo/ui-elements/Button';
import styled from '@emotion/styled';
import FlexBox from '@argo/principles/FlexBox';
import Rating from '@argo/ui-elements/Rating';
import colors from '@argo/principles/colors';
import RatingsAndReviewsContext from '../contexts';
import typography from '@argo/principles/typography';
import Logo from '../components/Logo'
import { postMessagePixallData } from '../helpers/analyticsTaggingHelper';
import createGuid from '../helpers/createGuid';
import { drrPixall } from '../constants';
import { pageInstanceId } from 'atc-js';


const Text = styled.p`
  ${typography.defaults.XSSemi};
  text-align: center;
  margin: 7px 0 0 0px;
`;

const ErrorText = styled.p`
  ${({ widgetTheme }) => 
    widgetTheme ? 
    widgetTheme.typography.defaults.SSemi : ''
  };
  text-transform: none;
  font-size: 12px;
  align-text: center;
  max-width: 210px;
  max-height: 92px;
  padding-left: 16px;
  padding-top: 25px;
`;

const FallbackText = styled.p`
  ${({ widgetTheme }) => 
    widgetTheme ? 
    widgetTheme.typography.defaults.SSemi : ''
  };
  text-transform: none;
  font-size: 11px;
  align-text: center;
  max-width: 210px;
  max-height: 92px;
  padding-left: 12px;
  padding-top: 35px;
`;

const StackDiv = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  max-height:92px;
`;

const RatingDiv = styled.div`
  margin-top: 12px;
  padding-top: 4px;
  padding-left: 2px;
`

const ButtonDiv = styled.div`
  margin-top: -10px;
  margin-left: -16px;
  padding-left: 16px;
  padding-bottom: 8px;
  font-family: 'Montserrat';
  font-weight: 600;
`;

const HorizontalRatings = () => {
  const {
    svocId,
    kbbRatings,
    widgetTheme,
    isAuthenticated,
    isCoreApiHealthy,
    optionalDedicatedPageUrl,
    clientId,
    userZip,
  } = useContext(RatingsAndReviewsContext);
  const originUrl = (window.location !== window.parent.location) ? document.referrer : document.location.href;
  const isRatingAvailable = kbbRatings.avgRating > 0.0;
  let roundedRating = parseFloat(kbbRatings.avgRating).toFixed(1).toString();
  if(roundedRating === '0.0'){
    roundedRating = 'N/A';
  }

  const eventData = {
    formEventId: createGuid(),
    zipCode: userZip,
    label: `rating::kbb::${roundedRating}`,
    pageInstanceId: pageInstanceId.getRandomId(),
    pageId: `dealer-ratings_premium_${originUrl}`,
  };

  let ctaUrl = '';
  if (optionalDedicatedPageUrl){
    ctaUrl = optionalDedicatedPageUrl;
  } else {
    ctaUrl = `/syndicated-widget/review/clientid/${clientId}`;
  }

  const handleCTAButton = () => {
    postMessagePixallData('click', 'dealership', svocId, { ...drrPixall, ...eventData });

    if (optionalDedicatedPageUrl){
      window.top.location.href = optionalDedicatedPageUrl;
    } else {
      window.open(ctaUrl,'popup','width=800,height=950');
    }
  };

  return (
    <div id='ratings-container'>
      {isAuthenticated && isCoreApiHealthy ? 
        <div style={{minWidth: "216px", height: "90px", border: `2px solid ${colors.neutral.trueGray}`, borderRadius: "8px", display: "inline-block", backgroundColor: "white"}}>
          <div>
            <Text>KBB.com Dealer Rating</Text>
            <FlexBox 
              verticalSpacing='micro' 
              horizontalSpacing='micro'
              minWidth="210px"
              style={{ paddingBottom: '4px', maxHeight: '92px', maxWidth: '210px', marginTop: "-15px"}}  
            >
              <div>
                <Logo providerName={"KBB.com Horizontal Dealer Ratings Badge"} />
              </div>
              {isRatingAvailable ? 
              <StackDiv>
                <RatingDiv>
                  <Rating 
                    size="Medium"
                    rating={roundedRating}
                    maxRating={5}
                    />
                </RatingDiv>
        
                <ButtonDiv>
                  <Button
                      id="see-our-reviews-horizontal-link"
                      buttonType='text'
                      text='See Our Reviews'
                      color='#006BC2'
                      width='160px'
                      sizeType='small'
                      onClick={handleCTAButton}
                      target="_blank"
                    />
                </ButtonDiv>
              </StackDiv>
              : <FallbackText widgetTheme={widgetTheme}>
                Rating Not Available 
              </FallbackText>
}
            </FlexBox>
          </div>         
      </div>
      : 
        <div style={{width: "216px", minHeight: "92px", border: `2px solid ${colors.neutral.trueGray}`, borderRadius: "8px", display: "inline-block" , backgroundColor: "white"}}>
          <ErrorText widgetTheme={widgetTheme}>
            { !isCoreApiHealthy ? "A technical error has occurred, please check again later." : "This subscription has expired."}
            
          </ErrorText>
        </div>
      }
    </div>
  );
}

export default HorizontalRatings;
