import React, { useContext } from 'react';
import FlexBox from '@argo/principles/FlexBox';
import Button from '@argo/ui-elements/Button';
import colors from '@argo/principles/colors';
import styled from '@emotion/styled';
import RatingsAndReviewsContext from '../contexts';
import Logo from '../components/Logo';
import RatingSection from '../components/RatingSection';
import { postMessagePixallData } from '../helpers/analyticsTaggingHelper';
import createGuid from '../helpers/createGuid';
import { drrPixall } from '../constants';
import { pageInstanceId } from 'atc-js';

const Text = styled.p`
  font-family: 'Montserrat';
  font-weight: 600;
  font-size: 16px;
  text-align:center;
  margin: 10px 0 0 0px;
  line-height: 20px;
`;

const ErrorText = styled.p`
  ${({ widgetTheme }) => 
    widgetTheme ? 
    widgetTheme.typography.defaults.SSemi : ''
  };
  text-transform: none;
  font-size: 12px;
  align-text: center;
  max-width: 180px;
  max-height: 132px;
`;


const StackDiv = styled.div`
  align-items: flex-start;
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding-top: 5px;
`;

const RatingsColumnDev = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  flex-direction: row;
`

const ButtonDiv = styled.div`
  padding-left: 14px;
  padding-right: 16px;
  font-size: 14px;
`;
const EmptyDiv = styled.div`
  align-items: flex-start;
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding-top: 51px;
  padding-right: 40px;
  padding-bottom: 60px;

`;

const SquareRatings = () => {
  const {
    svocId,
    kbbRatings,
    widgetTheme,
    isAuthenticated,
    isCoreApiHealthy,
    optionalDedicatedPageUrl,
    clientId,
    userZip,
  } = useContext(RatingsAndReviewsContext);
  const originUrl = (window.location !== window.parent.location) ? document.referrer : document.location.href;
  const isRatingAvailable = kbbRatings.avgRating > 0.0;
  let roundedRating = parseFloat(kbbRatings.avgRating).toFixed(1).toString();
  if(roundedRating === '0.0'){
    roundedRating = 'N/A';
  }

  const eventData = {
    formEventId: createGuid(),
    zipCode: userZip,
    label: `rating::kbb::${roundedRating}`,
    pageInstanceId: pageInstanceId.getRandomId(),
    pageId: `dealer-ratings_premium_${originUrl}`,
  };

  let ctaUrl = '';
  if (optionalDedicatedPageUrl){
    ctaUrl = optionalDedicatedPageUrl;
  } else {
    ctaUrl = `/syndicated-widget/review/clientid/${clientId}`;
  }

  const handleCTAButton = () => {
    postMessagePixallData('click', 'dealership', svocId, { ...drrPixall, ...eventData });

    if (optionalDedicatedPageUrl){
      window.top.location.href = optionalDedicatedPageUrl;
    } else {
      window.open(ctaUrl,'popup','width=800,height=950');
    }
  };

  return (
    <div id='ratings-container'>
      {isAuthenticated && isCoreApiHealthy ? 
        <div style={{ height: '176px', border: `2px solid ${colors.neutral.trueGray}`, borderRadius: "8px", display: "inline-block", backgroundColor: "white"}}>
          <div>
            <Text>KBB.com <br></br>Dealer Rating</Text>
            <FlexBox 
                verticalSpacing='micro' 
                horizontalSpacing='micro' 
                style={{ maxWidth: '180px'}}
            >
               {isRatingAvailable ? 
                <StackDiv>
                    <RatingsColumnDev>
                        <Logo providerName={"KBB.com Square Dealer Ratings Badge"} />
                        <RatingSection size={"square"}  maxRating={1} providerName={"KBB.com Square Dealer Ratings"} providerRating={kbbRatings.avgRating} style={{textAlign: "center"}} ratingsType='square' starRatingsWidth='18px' />
                    </RatingsColumnDev>

                    <ButtonDiv>
                        <Button
                            id="see-our-reviews-square-link"
                            buttonType='primary'
                            text='See Our Reviews'
                            width='148px'
                            sizeType='small'
                            onClick={handleCTAButton}
                            target="_blank"
                            fontSizeOverride='14'
                            minimizeSize={true}
                        />
                    </ButtonDiv>

                </StackDiv>
                    : <EmptyDiv>
                    <RatingsColumnDev>
                        <Logo providerName={"KBB.com Square Dealer Ratings Badge"} />
                        <RatingSection size={"square"}  maxRating={1} providerName={"KBB.com Square Dealer Ratings"} providerRating={kbbRatings.avgRating} style={{textAlign: "center"}} ratingsType='square' starRatingsWidth='18px' />
                    </RatingsColumnDev>
                </EmptyDiv>
                  }
            </FlexBox>
          </div>
        </div> 
      : 
        <div style={{minHeight: "176px" , border: `2px solid ${colors.neutral.trueGray}`, borderRadius: "8px", display: "inline-block", backgroundColor: "white"}}>
          <ErrorText widgetTheme={widgetTheme}>
            <div style={{paddingTop: "60px", textAlign: "center"}}>
            { !isCoreApiHealthy ? "A technical error has occurred, please check again later." : "This subscription has expired."}
            </div>            
          </ErrorText>
        </div>
      }
    </div>
  );
}

export default SquareRatings;